// Generated by Framer (d213f04)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, Link, RichText, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Material } from "https://framerusercontent.com/modules/6Ldpz1V0DkD45gXvi67I/PCgBX5d6MdQT7E7nhdXn/Material.js";
import * as sharedStyle from "../css/frwFdn91s";
const MaterialFonts = getFonts(Material);

const enabledGestures = {WG1EkjXmD: {hover: true}};

const cycleOrder = ["WG1EkjXmD", "ihp8xyBvK"];

const variantClassNames = {ihp8xyBvK: "framer-v-bt22o9", WG1EkjXmD: "framer-v-v48yhe"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1.2, stiffness: 300, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Menu - Mega": "ihp8xyBvK", "Variant 1": "WG1EkjXmD"}

const getProps = ({arrow, color, height, id, link, tap2, title, width, ...props}) => { return {...props, dMt7E2_tn: color ?? props.dMt7E2_tn ?? "var(--token-0c8fd507-faea-491a-8caf-ad6cd40c959b, rgb(40, 40, 42)) /* {\"name\":\"Neutral / 07\"} */", j_KE4sm_E: arrow ?? props.j_KE4sm_E, tt8lvbGGf: title ?? props.tt8lvbGGf ?? "About", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "WG1EkjXmD", x41Bgd582: link ?? props.x41Bgd582, xKRh9Snsp: tap2 ?? props.xKRh9Snsp} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string;link?: string;color?: string;arrow?: boolean;tap2?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, tt8lvbGGf, x41Bgd582, dMt7E2_tn, j_KE4sm_E, xKRh9Snsp, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "WG1EkjXmD", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapjqltrt = activeVariantCallback(async (...args) => {
if (xKRh9Snsp) {
const res = await xKRh9Snsp(...args);
if (res === false) return false;
}
})

const isDisplayed = () => {
if (baseVariant === "ihp8xyBvK") return true
return j_KE4sm_E
}

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-GxCGX", sharedStyle.className, classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={x41Bgd582} openInNewTab={false} {...addPropertyOverrides({ihp8xyBvK: {href: undefined}}, baseVariant, gestureVariant)}><motion.a {...restProps} className={`${cx("framer-v48yhe", className)} framer-1oeprh5`} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"WG1EkjXmD"} onTap={onTapjqltrt} ref={ref} style={{opacity: 1, ...style}} variants={{"WG1EkjXmD-hover": {opacity: 0.8}}} {...addPropertyOverrides({"WG1EkjXmD-hover": {"data-framer-name": undefined}, ihp8xyBvK: {"data-framer-name": "Menu - Mega"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-q8avul"} data-styles-preset={"frwFdn91s"} style={{"--framer-text-color": "var(--extracted-r6o4lv)"}}>About</motion.p></React.Fragment>} className={"framer-bhhlwh"} data-framer-name={"About"} layoutDependency={layoutDependency} layoutId={"gSzw957ef"} style={{"--extracted-r6o4lv": "var(--variable-reference-dMt7E2_tn-JMWkmIIwU)", "--framer-paragraph-spacing": "0px", "--variable-reference-dMt7E2_tn-JMWkmIIwU": dMt7E2_tn}} text={tt8lvbGGf} variants={{"WG1EkjXmD-hover": {"--extracted-r6o4lv": "var(--token-6d05c7e7-3db1-411c-8b7a-d2c7f93bf6a9, rgb(66, 68, 63)) "}}} verticalAlignment={"top"} withExternalLayout/>{isDisplayed() && (<motion.div className={"framer-1mzj4g-container"} layoutDependency={layoutDependency} layoutId={"zPeFpRKtd-container"} style={{rotate: 90}} variants={{"WG1EkjXmD-hover": {rotate: -90}}}><Material color={dMt7E2_tn} height={"100%"} iconSearch={"Home"} iconSelection={"ChevronRight"} iconStyle15={"Filled"} iconStyle2={"Filled"} iconStyle7={"Filled"} id={"zPeFpRKtd"} layoutId={"zPeFpRKtd"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div>)}</motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-GxCGX [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-GxCGX .framer-1oeprh5 { display: block; }", ".framer-GxCGX .framer-v48yhe { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-GxCGX .framer-bhhlwh { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-GxCGX .framer-1mzj4g-container { flex: none; height: 20px; position: relative; width: 20px; }", ".framer-GxCGX .framer-v-v48yhe .framer-v48yhe { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-GxCGX .framer-v48yhe { gap: 0px; } .framer-GxCGX .framer-v48yhe > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-GxCGX .framer-v48yhe > :first-child { margin-left: 0px; } .framer-GxCGX .framer-v48yhe > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 42
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"ihp8xyBvK":{"layout":["auto","auto"]},"fzz2h07Ld":{"layout":["auto","auto"]}}}
 * @framerVariables {"tt8lvbGGf":"title","x41Bgd582":"link","dMt7E2_tn":"color","j_KE4sm_E":"arrow","xKRh9Snsp":"tap2"}
 */
const FramerJMWkmIIwU: React.ComponentType<Props> = withCSS(Component, css, "framer-GxCGX") as typeof Component;
export default FramerJMWkmIIwU;

FramerJMWkmIIwU.displayName = "Header - Menu Item";

FramerJMWkmIIwU.defaultProps = {height: 24, width: 42};

addPropertyControls(FramerJMWkmIIwU, {variant: {options: ["WG1EkjXmD", "ihp8xyBvK"], optionTitles: ["Variant 1", "Menu - Mega"], title: "Variant", type: ControlType.Enum}, tt8lvbGGf: {defaultValue: "About", displayTextArea: false, title: "Title", type: ControlType.String}, x41Bgd582: {title: "Link", type: ControlType.Link}, dMt7E2_tn: {defaultValue: "var(--token-0c8fd507-faea-491a-8caf-ad6cd40c959b, rgb(40, 40, 42)) /* {\"name\":\"Neutral / 07\"} */", title: "Color", type: ControlType.Color}, j_KE4sm_E: {defaultValue: false, title: "Arrow", type: ControlType.Boolean}, xKRh9Snsp: {title: "Tap 2", type: ControlType.EventHandler}} as any)

addFonts(FramerJMWkmIIwU, [...MaterialFonts, ...sharedStyle.fonts])